<template>
  <section class="hero is-fullheight-with-navbar is-bg-gray p-6">
    <div id="user" v-if="selectedQuestionnaire===null">
      <div class="hero-head">
        <div class="navbar">
          <div class="container">
            <div class="title">
              <h1 class="title is-1">Bienvenido/a a tu consulta APT</h1>
            </div>
            <div id="navbarMenuHeroB" class="navbar-menu">
              <div class="navbar-end">
              <span class="navbar-item">
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-body">
        <div class="container has-text-left">
          <div class="box">
            <div class="block">
              <div class="text is-flex is-justify-content-space-between">
                <p class="title mb-0">{{ user.name }}</p>
                <p>{{ user.role.name }}</p>
              </div>
              <div class="text mt-0">
                <i class="subtitle">{{ user.company.name }}</i>
              </div>
            </div>
            <div class="block mt-3">
              <b-table
                :data="questionnaireList"
                :loading="loadingQuestionnaire"
                :current-page.sync="currentPage">
                <b-table-column centered field="id" label="REF" sortable v-slot="props">
                  {{ props.row.id }}
                </b-table-column>
                <b-table-column centered field="questionnaire_name" label="Cuestionario" sortable v-slot="props">
                  {{ props.row.name }}
                </b-table-column>
                <b-table-column centered field="company" label="Compañía" sortable v-slot="props">
                  {{ props.row.company.name }}
                </b-table-column>
                <b-table-column centered field="Status" label="Estado" sortable v-slot="props">
                  <b-tag :type="handleQuestionnaireStatusTag(props.row.status)" rounded>{{
                      handleQuestionnaireStatus(props.row.status)
                    }}</b-tag>
                </b-table-column>
                <b-table-column centered label="Acciones" v-slot="props">
                  <el-tooltip v-if="!props.row.status || props.row.status.id < 3" class="item" effect="dark"
                              content="Responder"
                              placement="top">
                    <el-button size="mini" type="warning" circle @click="handleUpdateQuestionnaire(props.row)">
                      <v-icon icon="pen"></v-icon>
                    </el-button>
                  </el-tooltip>
                  <b-tag v-else type="is-success" rounded>No se requieren acciones</b-tag>
                </b-table-column>
              </b-table>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-foot">
        <div class="container has-text-right">
          <div class="block">
          </div>
        </div>
      </div>
    </div>
    <dpt-questionnaire v-else :questionnaire-id="selectedQuestionnaire.id"
                       :is-preview="false" @questionnaireAnswered="handleQuestionnaireAnswered"></dpt-questionnaire>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import * as types from '@/store/types'
import DptQuestionnaire from '@/components/questionnaire/DptQuestionnaire'

export default {
  name: 'DptConsulting',
  components: { DptQuestionnaire },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    })
  },
  data () {
    return {
      currentPage: 1,
      pageSize: 20,
      loadingQuestionnaire: false,
      questionnaireList: [],
      selectedQuestionnaire: null
    }
  },
  methods: {
    fetchQuestionnaire () {
      this.loadingQuestionnaire = true
      this.axios.get(process.env.VUE_APP_API_GET_QUESTIONNAIRE_SUMMARY, {
        params: {
          searchQuery: 'company.id:' + this.user.company.id,
          userId: this.user.id,
          page: this.currentPage - 1,
          pageSize: this.pageSize,
          sort: 'id,desc'
        }
      }).then(response => {
        this.questionnaireList = response.data.content
      }).catch(err => {
        console.error('Error obteniendo el listado de cuestionarios', err)
        this.$notify.error({
          title: 'Error',
          message: 'No se ha podido obtener el listado de cuestionarios'
        })
      }).finally(() => {
        this.loadingQuestionnaire = false
      })
    },
    handleQuestionnaireAnswered () {
      this.fetchQuestionnaire()
      this.resetStatus()
    },
    handleQuestionnaireStatus (status) {
      let label = null
      if (!status) {
        label = 'Pendiente de responder'
      } else if (status.id === 2) {
        label = 'Borrador'
      } else if (status.id > 2) {
        label = 'Respondido'
      }
      return label
    },
    handleQuestionnaireStatusTag (status) {
      let tag = 'primary'
      if (!status) {
        tag = 'is-danger'
      } else if (status.id === 2) {
        tag = 'is-info'
      } else if (status.id > 2) {
        tag = 'is-success'
      }
      return tag
    },
    handleUpdateQuestionnaire (questionnaire) {
      this.selectedQuestionnaire = questionnaire
    },
    resetStatus () {
      this.selectedQuestionnaire = null
    }
  },
  created () {
    this.fetchQuestionnaire()
  }
}
</script>

<style scoped>

</style>
