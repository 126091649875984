var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "hero is-fullheight-with-navbar is-bg-gray p-6"
  }, [_vm.selectedQuestionnaire === null ? _c('div', {
    attrs: {
      "id": "user"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "hero-body"
  }, [_c('div', {
    staticClass: "container has-text-left"
  }, [_c('div', {
    staticClass: "box"
  }, [_c('div', {
    staticClass: "block"
  }, [_c('div', {
    staticClass: "text is-flex is-justify-content-space-between"
  }, [_c('p', {
    staticClass: "title mb-0"
  }, [_vm._v(_vm._s(_vm.user.name))]), _c('p', [_vm._v(_vm._s(_vm.user.role.name))])]), _c('div', {
    staticClass: "text mt-0"
  }, [_c('i', {
    staticClass: "subtitle"
  }, [_vm._v(_vm._s(_vm.user.company.name))])])]), _c('div', {
    staticClass: "block mt-3"
  }, [_c('b-table', {
    attrs: {
      "data": _vm.questionnaireList,
      "loading": _vm.loadingQuestionnaire,
      "current-page": _vm.currentPage
    },
    on: {
      "update:currentPage": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      }
    }
  }, [_c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "id",
      "label": "REF",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.id) + " ")];
      }
    }], null, false, 2188296272)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "questionnaire_name",
      "label": "Cuestionario",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.name) + " ")];
      }
    }], null, false, 2982927002)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "company",
      "label": "Compañía",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.company.name) + " ")];
      }
    }], null, false, 141863731)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "Status",
      "label": "Estado",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('b-tag', {
          attrs: {
            "type": _vm.handleQuestionnaireStatusTag(props.row.status),
            "rounded": ""
          }
        }, [_vm._v(_vm._s(_vm.handleQuestionnaireStatus(props.row.status)))])];
      }
    }], null, false, 58897504)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "label": "Acciones"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [!props.row.status || props.row.status.id < 3 ? _c('el-tooltip', {
          staticClass: "item",
          attrs: {
            "effect": "dark",
            "content": "Responder",
            "placement": "top"
          }
        }, [_c('el-button', {
          attrs: {
            "size": "mini",
            "type": "warning",
            "circle": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleUpdateQuestionnaire(props.row);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "icon": "pen"
          }
        })], 1)], 1) : _c('b-tag', {
          attrs: {
            "type": "is-success",
            "rounded": ""
          }
        }, [_vm._v("No se requieren acciones")])];
      }
    }], null, false, 1436562182)
  })], 1)], 1)])])]), _vm._m(1)]) : _c('dpt-questionnaire', {
    attrs: {
      "questionnaire-id": _vm.selectedQuestionnaire.id,
      "is-preview": false
    },
    on: {
      "questionnaireAnswered": _vm.handleQuestionnaireAnswered
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hero-head"
  }, [_c('div', {
    staticClass: "navbar"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('h1', {
    staticClass: "title is-1"
  }, [_vm._v("Bienvenido/a a tu consulta APT")])]), _c('div', {
    staticClass: "navbar-menu",
    attrs: {
      "id": "navbarMenuHeroB"
    }
  }, [_c('div', {
    staticClass: "navbar-end"
  }, [_c('span', {
    staticClass: "navbar-item"
  })])])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hero-foot"
  }, [_c('div', {
    staticClass: "container has-text-right"
  }, [_c('div', {
    staticClass: "block"
  })])]);
}]

export { render, staticRenderFns }